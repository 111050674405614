<template>
  <v-card>
    <v-container>
      <h4 class="h35 my-4 text-center">We would be delighted to share with you our passion for the work that’s taking place in the lives of precious children in Angola.</h4>
      <v-divider />
      <v-row class="my-4">
        <v-col cols="12" lg="8">
          <ContactForm />
        </v-col>
        <v-col cols="12" lg="4">
          <v-card rounded="0" color="black">
            <v-container class="ma-2 py-6">
              <h5 class="h5" style="color:white"><v-icon color="white">mdi-map-marker</v-icon>Write to Us: <br> 84 First Road West<br>Stoney Creek, Ontario<br> L8J 2S9</h5>
              <h5 class="h5 my-2" style="color:white"><v-icon color="white">mdi-phone</v-icon>Phone: <a href="tel:905-531-4601" class="text-decoration-none" style="color:#1992A7">905-531-4601</a> <br> or <a href="tel:905-541-8126" class="text-decoration-none" style="color:#1992A7"> 905-541-8126</a></h5>
              <h5 class="h5 mb-2" style="color:white"><v-icon color="white">mdi-email</v-icon>Email: <a href="mailto:info@hopeandangels.net" class="text-decoration-none" style="color:#1992A7"> info@hopeandangels.net</a></h5>
              <v-btn href="https://www.facebook.com/hopenangels" color="indigo" target="_blank"><v-icon>mdi-facebook</v-icon></v-btn>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import TopParallax from '@/components/TopParallax.vue'
import ContactForm from '@/components/ContactForm'

export default {
  components: {
    ContactForm
  },
  data() {
    return {
      imgSrc: require('@/assets/images/news5.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Contact'
      }
    }
  }
}
</script>

<style>
  .background {
    background-color: white !important;
  }
</style>
