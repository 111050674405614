<template>
  <div>
    <TopParallax
      title="CONTACT US"
      :img-src="imgSrc"
    />
    <ContactContent />
  </div>
</template>

<script>
import TopParallax from '@/components/TopParallax.vue'
import ContactContent from '@/components/hope/ContactContent.vue'

export default {
  components: {
    TopParallax,
    ContactContent
  },
  data() {
    return {
      imgSrc: require('@/assets/images/IMG_1526.jpg')
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Contact'
      }
    }
  }
}
</script>
